import React from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";

import {
    beautyContainer,
    main,
    ratioType,
    shapeImg,
    typesContent,
    typesHeading,
    typesImg,
    typesTitle,
} from "./beauty-type-section.module.scss";

import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import RatioImage from "../atoms/ratio-image";
import Title from "../atoms/title";

import TypUrody from "../../assets/images/svg/jaki-typ.svg";

export interface IBeautyTypeProps {
    image: IGatsbyImageData | undefined;
    title?: string;
    intro?: string;
    heading?: string;
    content?: string;
    colors?: string;
}

const BeautyTypeSection: React.FC<IBeautyTypeProps> = ({
    image,
    title = "",
    intro = "",
    heading = "",
    content = "",
    colors = "",
}) => {
    return (
        <SectionWrapper style={{ padding: "0 0 4em" }}>
            <Section
                column={"left"}
                columnTablet={"regular"}
                columnPhone={"regular"}
                className={beautyContainer}
            >
                {image && (
                    <RatioImage
                        className={shapeImg}
                        image={image}
                        ratioClass={ratioType}
                        objectFit={"contain"}
                    />
                )}
            </Section>
            <Section column={"right"} columnTablet={"regular"} columnPhone={"regular"}>
                <TypUrody className={typesImg} />
                {title && (
                    <Title size={"large"} fontStyle={"italic"} className={typesTitle}>
                        {title}
                    </Title>
                )}
                {intro && <div className={`${typesContent} ${main}`}>{intro}</div>}
                <div className={typesHeading}>Twoje kolory</div>
                {colors && <div className={typesContent}>{colors}</div>}
                {heading && <div className={typesHeading}>{heading}</div>}
                {content && <div className={typesContent}>{content}</div>}
            </Section>
        </SectionWrapper>
    );
};

export default BeautyTypeSection;
