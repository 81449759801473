// extracted by mini-css-extract-plugin
export var aboutBeauties = "beauty-type-module--about-beauties--Y8xn1";
export var beautyAboutSubtext = "beauty-type-module--beauty-about-subtext--pezF4";
export var beautyAboutText = "beauty-type-module--beauty-about-text--dpYZX";
export var beautyContainer = "beauty-type-module--beauty-container---relo";
export var beautyContent = "beauty-type-module--beauty-content--x1kYw";
export var beautyImg = "beauty-type-module--beauty-img--bjKHh";
export var beautyIntro = "beauty-type-module--beauty-intro--Wbip1";
export var beautyQuotation = "beauty-type-module--beauty-quotation--XWobB";
export var beautyTest = "beauty-type-module--beauty-test--cS+rW";
export var beautyTitle = "beauty-type-module--beauty-title--FNRhx";
export var beautyWrapper = "beauty-type-module--beauty-wrapper--CCpZz";
export var clueAttention = "beauty-type-module--clue-attention--DFHnQ";
export var clueContent = "beauty-type-module--clue-content--dApU7";
export var clueHeading = "beauty-type-module--clue-heading--CrFy6";
export var clueIcon = "beauty-type-module--clue-icon--NDOvP";
export var clueWrapper = "beauty-type-module--clue-wrapper--PLpx4";
export var heading = "beauty-type-module--heading--+ex9+";
export var icon = "beauty-type-module--icon--tRgtq";
export var quoteSignature = "beauty-type-module--quote-signature--wkOvA";
export var quoteText = "beauty-type-module--quote-text--xAb9X";
export var ratio = "beauty-type-module--ratio--zwwP0";
export var testContent = "beauty-type-module--test-content---J-rW";
export var testIcon = "beauty-type-module--test-icon--5fXDC";
export var testMethod = "beauty-type-module--test-method--HWjPO";
export var testWrapper = "beauty-type-module--test-wrapper--O+rqn";