// extracted by mini-css-extract-plugin
export var beautyContainer = "beauty-type-section-module--beauty-container--QrZCh";
export var main = "beauty-type-section-module--main--4GcOr";
export var ratio = "beauty-type-section-module--ratio--nd4-i";
export var ratioType = "beauty-type-section-module--ratio-type--FORZE";
export var shapeImg = "beauty-type-section-module--shape-img--uk8ui";
export var testContent = "beauty-type-section-module--test-content--xUJnU";
export var typesContent = "beauty-type-section-module--types-content--HfYuy";
export var typesHeading = "beauty-type-section-module--types-heading--dwW7q";
export var typesImg = "beauty-type-section-module--types-img--AS-9f";
export var typesTitle = "beauty-type-section-module--types-title--SCYJ3";