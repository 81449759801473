import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import {
    beautyIntro,
    icon,
    heading,
    aboutBeauties,
    beautyAboutText,
    beautyAboutSubtext,
    beautyImg,
    beautyQuotation,
    quoteText,
    quoteSignature,
    beautyContainer,
    beautyWrapper,
    ratio,
    beautyTitle,
    beautyContent,
    clueWrapper,
    clueAttention,
    clueIcon,
    clueHeading,
    clueContent,
    beautyTest,
    testWrapper,
    testMethod,
    testIcon,
    testContent,
} from "./beauty-type.module.scss";
import Typ from "../../assets/images/svg/typ-urody.svg";
import Clue from "../../assets/images/svg/clue.svg";
import Clothes from "../../assets/images/svg/clothes-hanger.svg";
import Light from "../../assets/images/svg/light-bulb.svg";
import Clues from "../../assets/images/svg/clues.svg";
import Diamond from "../../assets/images/svg/diamond-ring.svg";
import Warning from "../../assets/images/svg/warning.svg";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";
import { useI18next } from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import usePageMetadata from "../../hooks/use-page-metadata";
import getTranslationKey from "../../utils/get-translation-key";

import MainLayout from "../../layouts/main-layout";
import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import Title from "../atoms/title";
import RatioImage from "../atoms/ratio-image";
import BeautyTypeSection from "../organisms/beauty-type-section";
import FooterStylizeSection from "../molecules/footer-stylize-section";

interface IBeautyProps extends IPageBase {
    readonly data: { site: ISite } & { [key: string]: ImageDataLike };
}

const BeautyType: React.FC<IBeautyProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { site, mainImg, cold, warm, spring, summer, autumn, winter } = data;
    const { pageTitle, defaultStructuredData } = usePageMetadata(pageContext, site);
    const beautyTranslations = getTranslationKey("beauty");
    const beautiesImage = getImage(mainImg);
    const coldImg = getImage(cold);
    const warmImg = getImage(warm);

    const beautyTypes = [
        {
            id: 50,
            image: getImage(spring),
            title: "Wiosna",
            intro: t(beautyTranslations("spring.content.one")),
            colors: t(beautyTranslations("spring.content.two")),
            heading: "Gwiazdy o typie urody wiosna",
            content:
                "Nicole Kidman, Kate Hudson, Gwyneth Paltrow, Christina Hendricks, Sophia Loren.",
        },
        {
            id: 51,
            image: getImage(summer),
            title: "Lato",
            intro: t(beautyTranslations("summer.content.one")),
            colors: t(beautyTranslations("summer.content.two")),
            heading: "Gwiazdy o typie urody lato",
            content:
                "Reese Whiterspoon, Jennifer Aniston, Natalia Vodianova, Joanna Kulig, Cameron Diaz, Sarah Jessica Parker, Naomi Wats, Magdalena Cielecka, a także polska topmodelka Ania Jagodzińska.",
        },
        {
            id: 52,
            image: getImage(autumn),
            title: "Jesień",
            intro: t(beautyTranslations("autumn.content.one")),
            colors: t(beautyTranslations("autumn.content.two")),
            heading: "Gwiazdy o typie urody jesień",
            content:
                "Amy Adams, Cynthia Nixon, Emma Stone, Magdalena Walach, Eva Mendes, Julia Roberts, Natalie Portman, Lindsay Lohan, Julianne Moore.",
        },
        {
            id: 53,
            image: getImage(winter),
            title: "Zima",
            intro: t(beautyTranslations("winter.content.one")),
            colors: t(beautyTranslations("winter.content.two")),
            heading: "Gwiazdy o typie urody zima",
            content:
                "Katherina Zeta-Jones, Salma Hayek i Monica Bellucci, Liv Tyler, Nigella Lawson, Courteney Cox.",
        },
    ];

    return (
        <MainLayout
            SEOProps={{
                title: pageTitle,
                structuredData: defaultStructuredData,
            }}
        >
            <SectionWrapper bgColor={"beige-light"}>
                <Section
                    column={"full"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={beautyIntro}
                >
                    <Typ className={icon} />
                    <Title size={"large"} align={"center"} className={heading}>
                        Znajdź najlepszy kolor dla siebie.
                    </Title>
                </Section>
            </SectionWrapper>
            <SectionWrapper>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={aboutBeauties}
                >
                    <div className={beautyAboutText}>{t(beautyTranslations("about"))}</div>
                    <div className={beautyAboutSubtext}>{t(beautyTranslations("subtext"))}</div>
                </Section>
                <Section
                    column={"right"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={beautyImg}
                >
                    {beautiesImage && <GatsbyImage image={beautiesImage} alt={""} />}
                </Section>
            </SectionWrapper>
            <SectionWrapper>
                <Section
                    column={"narrow-right"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={beautyQuotation}
                >
                    <div className={quoteText}>
                        <p>Najlepszy kolor na świecie to taki, który dobrze na Tobie wygląda.</p>
                    </div>
                    <div className={quoteSignature}>Coco Chanel</div>
                </Section>
            </SectionWrapper>
            <SectionWrapper>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={beautyContainer}
                >
                    {coldImg && (
                        <RatioImage image={coldImg} ratioClass={ratio} objectFit={"contain"} />
                    )}
                </Section>
                <Section
                    column={"right"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={beautyWrapper}
                >
                    <div>
                        <Title size={"large"} fontStyle={"italic"} className={beautyTitle}>
                            {t(beautyTranslations("cold.title"))}
                        </Title>
                        <div className={beautyContent}>{t(beautyTranslations("cold.content"))}</div>
                    </div>
                    <div className={clueWrapper}>
                        <div className={clueAttention}>
                            <Clue className={clueIcon} />
                            <p className={clueHeading}>Wskazówka</p>
                        </div>
                        <div className={clueContent}>{t(beautyTranslations("cold.clue"))}</div>
                    </div>
                </Section>
            </SectionWrapper>
            <SectionWrapper>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={beautyContainer}
                >
                    {warmImg && (
                        <RatioImage image={warmImg} ratioClass={ratio} objectFit={"contain"} />
                    )}
                </Section>
                <Section
                    column={"right"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={beautyWrapper}
                >
                    <div>
                        <Title size={"large"} fontStyle={"italic"} className={beautyTitle}>
                            {t(beautyTranslations("warm.title"))}
                        </Title>
                        <div className={beautyContent}>{t(beautyTranslations("warm.content"))}</div>
                    </div>
                    <div className={clueWrapper}>
                        <div className={clueAttention}>
                            <Clue className={clueIcon} />
                            <p className={clueHeading}>Wskazówka</p>
                        </div>
                        <div className={clueContent}>{t(beautyTranslations("warm.clue"))}</div>
                    </div>
                </Section>
            </SectionWrapper>
            <SectionWrapper bgColor={"beige-light"}>
                <Section column={"regular"} columnTablet={"regular"} columnPhone={"regular"}>
                    <Title
                        size={"large"}
                        fontStyle={"italic"}
                        align={"center"}
                        className={beautyTest}
                    >
                        Zrób dla pewności test:
                    </Title>
                </Section>
                <Section
                    column={"left"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={testWrapper}
                >
                    <div className={testMethod}>
                        <Clothes className={testIcon} />
                        <Title size={"medium"} fontStyle={"italic"}>
                            Metoda na neutrale
                        </Title>
                    </div>
                    <div className={testContent}>
                        Ta metoda polega na przymierzaniu ubrań w chłodnych i w ciepłych kolorach.
                        Najlepiej ten test wypada przy używaniu do tego celu ubrań szarych i
                        beżowych, czyli barw neutralnych. Jeśli lepiej Ci w beżach jesteś typem
                        ciepłym. Jeśli zaś lepiej prezentujesz się w szarościach, masz chłodny typ
                        urody.
                    </div>
                    <div className={testMethod}>
                        <Light className={testIcon} />
                        <Title size={"medium"} fontStyle={"italic"}>
                            Ciekawostka
                        </Title>
                    </div>
                    <div className={testContent}>
                        Nasza intuicja w kwestii, w kwestii intensywności kolorów idzie w parze z
                        intensywnością urody (lub temperament) ale na ogół jest tak, że kobiety o
                        jasnej cerze i delikatnych rysach wybierają barwy jasne, rozbielone, a
                        kobiety o ciemniejsze skórze i włosach lepiej czują się w kolorach
                        nasyconych.
                    </div>
                    <div className={testMethod}>
                        <Clues className={testIcon} />
                        <Title size={"medium"} fontStyle={"italic"}>
                            Wskazówka 1
                        </Title>
                    </div>
                    <div className={testContent}>
                        Musisz przykładać do twarzy, jak największą liczbę kolorów, by określić
                        jakie kolory do Ciebie pasują. Budowanie garderoby wg jednej palety barw np.
                        ciepłej lub zimnej znacznie ułatwia komponowanie zestawów. Nie musisz się
                        martwić czy te rzeczy do siebie pasują.
                    </div>
                </Section>
                <Section
                    column={"right"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={testWrapper}
                >
                    <div className={testMethod}>
                        <Diamond className={testIcon} />
                        <Title size={"medium"} fontStyle={"italic"}>
                            Metoda na biżuterię
                        </Title>
                    </div>
                    <div className={testContent}>
                        Załóż biżuterię w kolorze złotym i srebrnym. Jeśli lepiej wyglądasz w złocie
                        jesteś typem ciepłym. Natomiast w przypadku typu chłodnego lepiej Ci będzie
                        w srebrze.
                    </div>
                    <div className={testMethod}>
                        <Warning className={testIcon} />
                        <Title size={"medium"} fontStyle={"italic"}>
                            Uwaga
                        </Title>
                    </div>
                    <div className={testContent}>
                        Faktycznie źle dobrane kolory mogą nas „wymyć”, postarzyć czy spowodować
                        wrażenie, jakbyśmy danego dnia się źle czuły. Ale to po prostu może być wina
                        faktury danego materiału czy tego, w jaki sposób odbija on światło!
                    </div>
                    <div className={testMethod}>
                        <Clues className={testIcon} />
                        <Title size={"medium"} fontStyle={"italic"}>
                            Wskazówka 2
                        </Title>
                    </div>
                    <div className={testContent}>
                        Jeśli jest kolor, który Ci się podoba, ale uznajesz go za nie twarzowy to po
                        prostu „odsuń” go od twarzy i noś niżej – w formie spodni, spódnicy czy
                        butów, torebki, paska.
                    </div>
                </Section>
            </SectionWrapper>
            <SectionWrapper>
                <Section column={"full"} columnTablet={"regular"} columnPhone={"regular"}>
                    <Title
                        size={"large"}
                        fontStyle={"italic"}
                        align={"center"}
                        className={beautyTest}
                    >
                        Sprawdź, jakim typem urody jesteś:
                    </Title>
                </Section>
            </SectionWrapper>
            <SectionWrapper style={{ padding: "0 0 4em" }}>
                {beautyTypes.map((type) => {
                    return <BeautyTypeSection key={type.id} {...type} />;
                })}
            </SectionWrapper>
            <FooterStylizeSection data={data} />
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        mainImg: file(relativePath: { eq: "beauties.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        cold: file(relativePath: { eq: "zimny.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        warm: file(relativePath: { eq: "cieply.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        spring: file(relativePath: { eq: "wiosna.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        summer: file(relativePath: { eq: "lato.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        autumn: file(relativePath: { eq: "jesien.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        winter: file(relativePath: { eq: "zima.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        logo: file(relativePath: { eq: "emilka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default BeautyType;
